<template>
    <div class="teachMethod">
        <div class="listArea">
            <div class="item">
                <div class="titleT">
                    我们的教学方法
                </div>
                <div class="contentT">
                    <div>
                        放下你对语言天赋的焦虑。<br>
                        在<span class="highlight">游戏闯关</span>的学习模式里，你会发现学习语言原来可以如此轻松有趣，甚至还有点上瘾。<br>
                        多样的题型和简便的操作，让初学者成就感满满，充满继续学习的动力！
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="wrapItem">
                    <div>
                        <img class="picA" :src="jxPic.a">
                    </div>
                    <div class="contentArea">
                        <div class="titleT">
                            轻松发音入门
                        </div>
                        <div class="contentT">
                            <div>
                                了解一门语言的发音方式，不仅能让你快速认识这门语言的最基础元素，还会让你之后的学习事半功倍。
                            </div>
                            <div>
                                「鹿老师说外语」单独设立<span class="highlight">「发音入门」</span>单元，将发音规则有条有理地列出。
                                <br>配上由我们精选的母语者录制的高清音频，为你的第一步学习打下坚实基础。
                            </div>
                            <div>
                                日语和韩语更配有<span class="highlight">字母小关卡</span>和<span class="highlight">发音表</span>跟读，
                                帮助你更快更好记忆五十音和韩语字母。
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="wrapItem">
                    <div class="contentArea">
                        <div class="titleT">
                            系统的语法结构
                        </div>
                        <div class="contentT">
                            <div>
                                如果我们将语言视为一座城堡，那么语法就是一门<span class="highlight">语言的框架结构</span>，
                                是城堡得以成型的支撑，单词、 词组则是填充其中的砖块。
                            </div>
                            <div>
                                如果没有建好框架，就忙着堆砌砖块，远看或许是一座高楼，但近看却是一座危楼，摇摇晃晃，令人担忧。
                            </div>
                            <div>
                                <div><span class="highlight">系统的语法知识</span>是成功学习外语的必经之路。</div>
                            </div>
                            <div>
                                「鹿老师说外语」的课程以<span class="highlight">语法为主线</span>，将一门语言的语法结构从
                                易到难排列，搭配常用的词汇和句子， 融入一小节一小节的课程中。
                            </div>
                        </div>
                    </div>
                    <div>
                        <img class="picA" :src="jxPic.b">
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="wrapItem">
                    <div>
                        <img class="picA" :src="jxPic.c">
                    </div>
                    <div class="contentArea">
                        <div class="titleT">
                            与你有关的内容
                        </div>
                        <div class="contentT">
                            <div>
                                学习那些与你息息相关的内容，有利于保持专注，提升效率。
                            </div>
                            <div>
                                「鹿老师说外语」 <span class="highlight">以语法为主线</span>，将<span class="highlight">常用的词汇</span>和句子按照难度
                                分类安排在各单元，并将相似的话题汇总在同一单元。
                            </div>
                            <div>
                                用图片学习单词，先明白整体意思，再接触单词。用音频加强句子记忆，通过反复的复现和练习，不知不觉记住新知识。
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="wrapItem">
                    <div class="contentArea">
                        <div class="titleT">
                            高质量音频浸泡
                        </div>
                        <div class="contentT">
                            <div>
                                没有适合初学者的母语者音频？
                            </div>
                            <div>
                                无需担心。「鹿老师说外语」课程中所有单词和句子，均有<span class="highlight">高清真人母语者音频</span>。
                            </div>
                            <div>
                                从数十位录音者中精选最地道最适合初学者的声音，在专业录音室录制，严格控制语速，
                                保证你听到的音频<span class="highlight">高质、高清、语速合适</span>。
                            </div>
                            <div>
                                语言作为交流工具，听进去说出来。优质的音频资源为你营造良好的外语环境，更能作为增强记忆的媒介。
                            </div>
                        </div>
                    </div>
                    <div>
                        <img class="picA" :src="jxPic.d">
                    </div>
                </div>
            </div>

            <div class="item specialItem">
                <div class="wrapItem">
                    <div>
                        <img class="picA" :src="jxPic.e">
                    </div>
                    <div class="contentArea">
                        <div class="titleT">
                            多种练习反复输出
                        </div>
                        <div class="contentT">
                            <div>
                                语言的听说读写能力，需要大量的练习，才可以做到熟练应用。
                            </div>
                            <div>
                                在「鹿老师说外语」中，单元内边学边练的主模式，<span class="highlight">包含10+种练习方式</span>，从看外语识中文、
                                看词选图、选择正确翻译、句内排序、单词插入到听音辩词、单词拼写、句子拼写等等。
                            </div>
                            <div>
                                多样的练习方式，随时伴随的音频，将听、读、写贯穿整个课程，时刻输出所学。
                            </div>
                            <div>
                                此外，<span class="highlight">「情景故事」的配音练习</span>，在轻松好玩的小故事中提升你的口语能力。
                            </div>
                            <div>

                                复习页面的<span class="highlight">「抽认卡」</span>功能，随时随地快速复习；
                                <span class="highlight">「错题本」</span>功能，针对性练习。
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="wrapItem lastItem">
                    <div>
                        <img class="picA" :src="jxPic.f">
                    </div>
                    <div class="contentT">
                        <div>
                            每个人都有选择如何书写自己人生的权利，也正因如此我们的世界才是丰富多彩的。
                            而见过更多可能，看过多种故事，你会更明白自己喜欢和追求的是什么，做出自己的决定。
                            <br>
                            <span class="highlight">学习一门语言，相当于打开一个新的世界。</span>
                            <br>
                            语言的背后是文化、世界观、思维模式。你每学习一门新语言，就像换了一个脑子，多了一种认识世界的方式。
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style src="./TeachMethod.scss" lang="scss"></style>

<script>
    import Constants from "../../../../utils/Constants"

    export default {
        data() {
            return {
                jxPic: Constants.landPagePic.second_rank.jx
            }
        }
    }
</script>

